_cruxUtils._cruxLocale = {
"crux.no.bc.message":"Hi\u00e7bir genel bak\u0131\u015f mevcut de\u011fil. Daha fazla ayr\u0131nt\u0131 g\u00f6r\u00fcnt\u00fclemek i\u00e7in kayd\u0131n \u00fczerine t\u0131klay\u0131n",
"crm.appointments.status.markcompleted1": "Tamamland\u0131 Olarak \u0130\u015faretle",
"crm.mass.actions.all.selected": "Bu g\u00f6r\u00fcn\u00fcmdeki t\u00fcm<span class=\"dIB vaM\">\u00a0</span><span class='mL3 mR3 crm-font-bold dIB vaM recordCountHt'> {0}\u00a0</span><span class=\"dIB vaM\"> {1} se\u00e7ildi.</span>",
"crm.module.selectall": "Bu g\u00f6r\u00fcn\u00fcmdeki T\u00fcm {0} \u00f6gelerini se\u00e7",
"records": "kay\u0131tlar",
"crm.record.not.accessible": "Kay\u0131t(lar)a Eri\u015filemiyor",
"crm.view.calls.recording.err.msg": "Ses kayd\u0131n\u0131n oynat\u0131labilir bir ses URL\u2019si yok.",
"crm.listview.selected.records": "<span class='mL3 crm-small-font-size crm-font-bold vaM dIB'>{0} </span><span class=\"mL3 seletcedRecords\">\u00a0{1} se\u00e7ildi.</span>",
"totalrecords": "Toplam Kay\u0131tlar",
"crm.listview.options.text.clip": "Metni K\u0131salt",
"crm.listview.options.text.wrap": "Metni Kayd\u0131r",
"crm.listview.options.reset.width": "S\u00fctun Boyutunu S\u0131f\u0131rla",
"crm.listview.options.manage": "S\u00fctunlar\u0131 Y\u00f6net",
"crm.listview.customview.locked": "Bu g\u00f6r\u00fcn\u00fcm kilitlendi\u011finden s\u00fctunlar \u00f6zelle\u015ftirilemiyor",
"crm.api.apidashboard.SortBy": "S\u0131ralama \u00d6l\u00e7\u00fct\u00fc",
"crm.privacy.listview.consent.locked": "Kay\u0131t, i\u015fleme i\u00e7in kilitlendi",
"crm.label.reschedule.call": "{0} yeniden planla",
"crm.button.edit": "D\u00fczenle",
"Delete": "Sil",
"crm.appointments.status.markcancel": "{0} \u0130ptal",
"crm.appointments.status.markreschedule": "{0} yeniden planla",
"crm.label.cancel.call": "{0} \u00d6\u011fesini \u0130ptal Et",
"crm.label.mark.completed": "Tamamland\u0131 Olarak \u0130\u015faretle",
"crm.listview.customview.locked": "Bu g\u00f6r\u00fcn\u00fcm kilitlendi\u011finden s\u00fctunlar \u00f6zelle\u015ftirilemiyor",
"pf.deleted.state": "Durum Silindi",
"crm.project.integration.title": "Zoho Projects",
"crm.integ.sync.message": "{0} ile e\u015fitle",
"crm.schedules.prefix.in": "\u0130\u00e7inde",
"crm.record.record.locked.other": "Di\u011fer profil kullan\u0131c\u0131lar\u0131 i\u00e7in kay\u0131t kilitlendi.",
"crm.record.lock.record.locked": "Kay\u0131t kilitli.",
"crm.approvalProcess.label.waitingForApproval": "Onay bekliyor",
"crm.reviewprocess.record.review.pending": "G\u00f6zden ge\u00e7irme i\u00e7in bekliyor",
"crm.approvalProcess.label.waitingForFindAndMerge": "Yinelenenleri birle\u015ftirme i\u015flemi devam ediyor.",
"crm.privacy.listview.consent.locked": "Kay\u0131t, i\u015fleme i\u00e7in kilitlendi",
"crm.zia.vision.processing": "Zia g\u00f6r\u00fcnt\u00fcy\u00fc/g\u00f6r\u00fcnt\u00fcleri do\u011fruluyor.",
"crm.zia.vision.rejected.msg": "G\u00f6r\u00fcnt\u00fc do\u011frulamas\u0131 hatas\u0131 nedeniyle kay\u0131t reddedildi.",
"crm.zia.vision.record.failure.msg": "G\u00f6r\u00fcnt\u00fc do\u011frulamas\u0131 hatas\u0131 nedeniyle kay\u0131t onay bekliyor.",
"crm.alert.max.cvcols.reached": "En fazla {0} s\u00fctun se\u00e7ebilirsiniz.",
"crm.label.small.tags": "Etiketler",
"crm.label.edit.module": "{0} D\u00fczenle",
"crm.social.integ.no.result": "Sonu\u00e7 bulunamad\u0131",
"custommodule.crmfield.fl.name": "{0} Ad\u0131",
"Appointment": "Randevu",
"crm.button.create.call": "Arama Olu\u015ftur",
"crm.module.create": "{0} Olu\u015ftur",
"crm.customview.activty.badge": "Etkinlik Rozeti",
"crm.button.callnow": "\u015eimdi aray\u0131n",
"crm.manualcalllist.complete.call": "G\u00fcnl\u00fc\u011fe bir arama kaydet",
"crm.label.schedule.call": "\u00c7a\u011fr\u0131 zamanla",
"crm.filter.label.replied":"yan\u0131tlanan",
"bytes": "bayt",
"crm.field.label.user.lookup": "Kullan\u0131c\u0131",
"crm.translation.refresh.cases": "Ger\u00e7ekle\u015ftirmeye \u00e7al\u0131\u015ft\u0131\u011f\u0131n\u0131z eylem tamamlanamad\u0131. L\u00fctfen sayfay\u0131 yenileyin ve tekrar deneyin.",
"crm.project.sync.notes.info": "Yaln\u0131zca ili\u015fkili Projelere dair g\u00f6rev yorumlar\u0131 g\u00f6sterilecektir",
"crux.note.content.limit.warning.msg":"Notlar i\u00e7eri\u011fine yaln\u0131zca {0} karakter kaydedilmesine izin verildi",
"crux.note.formatting.options":"Bi\u00e7imlendirme se\u00e7enekleri",
"crm.label.required": "Zorunlu",
"crux.condition.not.belongs.to":"{0} \u00f6\u011fesine ait de\u011fil",
"crm.project.sync.notes.info": "Yaln\u0131zca ili\u015fkili Projelere dair g\u00f6rev yorumlar\u0131 g\u00f6sterilecektir",
"crm.sf.permission.no.edit": "Girdileri g\u00fcncelleme izniniz yok.",
"crm.sf.permission.no.create": "Girdi ekleme izniniz yok.",
"crm.sf.permission.no.delete": "Girdileri silme izniniz yok.",
"crux.note.this.note":"bu not",
"KB": "KB",
"MB": "MB",
"current.logged.in.user.role": "Oturum A\u00e7an Kullan\u0131c\u0131 Rol\u00fc",
"Unit Price": "Liste Fiyat\u0131",
"Qty in Stock": "Stoktaki Miktar",
"crm.label.Product Code": "\u00dcr\u00fcn Kodu",
"TotalAfterDiscount": "\u0130ndirimden Sonraki Toplam",
"crm.lookupfilter.entity.errormsg": "Se\u00e7ili {0} arama \u00f6l\u00e7\u00fctlerini kar\u015f\u0131lam\u0131yor.",
"crm.iamexception.maxlen": "{1} i\u00e7in en fazla {0} karakter girebilirsiniz",
"crm.record.locking.permission.denied": "Bu eylem, kay\u0131t kilitlendi\u011finden ger\u00e7ekle\u015ftirilemez.",
"List Price": "Teklif Fiyat\u0131",
"crm.label.no.more.records": "Ba\u015fka kay\u0131t yok",
"crm.workflow.rule.created.dateAndTime":"{2}\u2018deki {1} \u00f6\u011fesindeki {0}",
"crux.new.note.msg":"Bu not ne hakk\u0131nda?",
"crm.image.uploaded":"G\u00f6r\u00fcnt\u00fc Kar\u015f\u0131ya Y\u00fcklendi",
"crm.imageupload.drag.drop.here":"G\u00f6r\u00fcnt\u00fcleri buraya s\u00fcr\u00fckleyin ve b\u0131rak\u0131n",
"crm.fileupload.drag.drop.here":"Dosyalar\u0131 buraya s\u00fcr\u00fckleyin ve b\u0131rak\u0131n",
"crm.fileupload.wait.msg":"L\u00fctfen se\u00e7ili dosyalar kar\u015f\u0131ya y\u00fcklenene kadar bekleyin",
"crm.fileupload.attach.fail":"Ekleme ba\u015far\u0131s\u0131z oldu",
"crm.fileupload.support.format":"Zia\u2019n\u0131n \u00f6\u011frenmesi i\u00e7in desteklenen dosya bi\u00e7imi (jpeg, jpg, png, pdf)",
"custmr.prtl.user.role": "Portal Kullan\u0131c\u0131s\u0131",
"crm.subform.empty.alert.header":"Bo\u015f sat\u0131r kaydedilemez",
"crm.crud.lookup.module.inaccessible": "Bu arama mod\u00fcl\u00fcne eri\u015fim izniniz yok",
"crm.crud.lookup.inaccessible.record": "Kay\u0131t mevcut veya size eri\u015filebilir de\u011fil",
"crm.custombutton.valid.weburl.check": "L\u00fctfen birka\u00e7 de\u011fer girin.",
"crm.label.subform.goto.top": "Listenin ba\u015f\u0131na git",
"crm.label.subform.row.show.all": "T\u00fcm\u00fcn\u00fc G\u00f6ster ({0})",
"cpq.pr.nodiscount": "\u0130li\u015fkilendirilmi\u015f indirim yok",
"crm.inventory.lineitem.no.pricebook.new": "\u0130li\u015fkilendirilen {0} yok.",
"crm.label.subform.allowedlimit": "\u0130zin verilen {0} sat\u0131r s\u0131n\u0131r\u0131na ula\u015f\u0131ld\u0131",
"crm.reports.integ.okay": "Tamam",
"crm.crud.subform.deleted.record": "G\u00fcncellenen Alt Form sat\u0131rlar\u0131 zaten silindi. L\u00fctfen sayfay\u0131 yenileyin ve tekrar deneyin.",
"crux.criteria.fieldlabel.valid.check":"L\u00fctfen ge\u00e7erli bir alan etiketi girin",
"crm.subform.delete.confirm.header":"Alt Form Satırını Sil",
"crm.subform.delete.confirm.msg": "{0} öğesinde seçilen satırı silmek istediğinizden emin misiniz?",
"crm.subform.delete.tooltip.msg" :"Satır silinemiyor. {0} için en az bir satır girişi gerekiyor.",
	"crm.condition.belongs.to":"{0} ki\u015fisine ait",//no i18n
"crm.condition.not.belongs.to":"{0} ki\u015fisine ait de\u011fil",//no i18n
"crm.orchestration.error.selectvalue": "L\u00fctfen bir de\u011fer se\u00e7in",
	"crm.attach.upload.image":"G\u00f6r\u00fcnt\u00fcleri Ekle",//no i18n
"crm.general.select.photo":"G\u00f6r\u00fcnt\u00fc Se\u00e7",//no i18n
"crm.imageupload.wait.msg":"L\u00fctfen se\u00e7ili g\u00f6r\u00fcnt\u00fcler kar\u015f\u0131ya y\u00fcklenene kadar bekleyin.",//no i18n
"crm.image.filepicker.desc.draganddrop":"G\u00f6r\u00fcnt\u00fcleri S\u00fcr\u00fckle ve B\u0131rak.",//no i18n
"crm.image.filepicker.desc.browse":"G\u00f6r\u00fcnt\u00fclere G\u00f6z Atmak i\u00e7in T\u0131klay\u0131n...",//no i18n
"crm.attach.upload.userinfo":"Toplam boyut <span class",//no i18n
"crm.image.supported.formats":"Desteklenen bi\u00e7imler: JPEG, PNG, GIF ve BMP.",//no i18n
"crm.imageupload.allowed.field.length":"Yaln\u0131zca en fazla {0} g\u00f6r\u00fcnt\u00fc kar\u015f\u0131ya y\u00fckleyebilirsiniz.",//no i18n
"crm.general.crop.and.set":"K\u0131rp ve Ayarla",//no i18n
"crm.attach.option.label":"Ekle",//no i18n
"crm.image.crop.and.rotate":"K\u0131rp ve D\u00f6nd\u00fcr",//no i18n
"crm.image.resolution":"\u00c7\u00f6z\u00fcn\u00fcrl\u00fck",//no i18n
"crm.attachment.size":"Boyut",//no i18n
"crm.label.close":"Kapat",//no i18n
"crm.label.previous":"\u00d6nceki",//no i18n
"crm.label.next":"Sonraki",//no i18n
"crm.subform.record.create.maxrow.limit": "{1} i\u00e7inde maksimum {0} kayda izin veriliyor",
"crm.label.subform.addrows": "Sat\u0131r Ekle",
"crm.tax.association.check": "Bu \u00fcr\u00fcnle ili\u015fkilendirilmi\u015f hi\u00e7bir vergi yoktur.",
	"crm.gallery.inventory.template.discount": "\u0130ndirim",
	"crm.inventory.discount.scheme.range": "Aral\u0131k",
	"Description": "A\u00e7\u0131klama",
	"crm.inventory.lineitem.no.pricebook": "Hi\u00e7bir Fiyat Defteri ili\u015fkilendirilmedi.",
	"crm.recordImage.previewimage":"G\u00f6r\u00fcnt\u00fcy\u00fc \u00d6nizle",//no i18n
"crm.FileuploadField.addNewImage":"Resmi Kar\u015f\u0131ya Y\u00fckle",//no i18n
"crm.fileuploader.removefile":"Kald\u0131r",//no i18n
	"voc.gc.configure":"Yap\u0131land\u0131r",//no i18n
"Edit":"D\u00fczenle",//no i18n
	"crm.criteria.secondaryfield.valid.check" : "L\u00fctfen {0} i\u00e7in e\u015fle\u015fen de\u011feri girin",
	"crm.label.field":"Alan",//no i18n
"crm.label.value":"De\u011fer",//no i18n
	"crm.button.clone":"Kopyala",//no i18n
	"sentiment.criteria.wrongcriteria":"\u00d6l\u00e7\u00fct de\u011feri en fazla {0} olabilir",//no i18n
"crm.mb.field.common.splc":"\u00d6zel karakterlere izin verilmiyor. Ge\u00e7erli bir de\u011fer girin.",//no i18n
	"crm.view.attachment.download":"\u0130ndir",//no i18n
"crm.label.view":"G\u00f6r\u00fcn\u00fcm",//no i18n
	"crm.label.field.plural":"alanlar",//no i18n
	"crm.label.in.minutes":"{0} (dakika olarak)",//no i18n
	"crm.security.roles.list":"Roller Listesi",//no i18n
"crm.security.roles.lookup.info":"L\u00fctfen listeden bir rol se\u00e7in.",//no i18n
"crm.territory.addterritory":"B\u00f6lge Ekle",//no i18n
"crm.title.edit.territory":"B\u00f6lge D\u00fczenle",//no i18n
"crm.territory.title.assign.territories":"B\u00f6lge Tahsis Et",//no i18n
	"crm.label.context.help":"Yard\u0131m",//no i18n
"crm.label.from":"\u015eu Tarihten",//no i18n
"crm.label.to":"\u015eu Tarihe",//no i18n
	"workflow.option.webhookFailure.fromDate":"Ba\u015flang\u0131\u00e7 Tarihi",//no i18n
"workflow.option.webhookFailure.toDate":"Biti\u015f Tarihi",//no i18n
"crm.custom.field.less.than.equalto":"{0}, {1}\u2019den az veya e\u015fit olmal\u0131d\u0131r.",//no i18n
	"crm.template.listview.search.no.results":"Sonu\u00e7 bulunamad\u0131",//No I18n
	"crm.label.tag.new":"Yeni Etiket",//No I18n
	"crm.label.enter.tag":"Etiketleri Girin",//No I18n
	"crux.comboBox.max.limit":"En fazla {0} {1} se\u00e7ebilirsiniz.",//NO I18n
	"Administrator":"Y\u00f6netici",//No I18n
	"Standard":"Standart",//No I18n
	"crm.button.add":"Ekle",//NO I18n
	"crm.label.users":"Kullan\u0131c\u0131lar", //NO I18n
  "crm.workflow.alert.roles":"Roller", //NO I18n
  "crm.security.groups":"Gruplar", //NO I18n
	"crm.label.available" : "Uygun", //NO I18n
	"crm.label.assign.manually" : "Tahsis Et", //NO I18n
	"crm.globalsearch.option.all": "T\u00fcm Mod\u00fcller", //NO I18n
	"webform.status.Active":"Etkin", //NO I18n
	"Inactive":"Aktif De\u011fil", //NO I18n
  "Confirmed":"Onayland\u0131", //NO I18n
  "crm.user.component.unconfirmed":"Onaylanmam\u0131\u015f",//no i18n
  "DeletedUser":"Silindi", //NO I18n
  "crm.feed.group.admin":"Y\u00f6netici", //NO I18n
  "crm.ln.lable.current":"\u015eimdi", //NO I18n
	"crm.label.selected": "Se\u00e7ili",//NO I18n
	"crm.auditlog.user": "Kullan\u0131c\u0131", //NO I18n
	"cob.role": "Rol", //NO I18n
	"zoho.email": "E-posta", //NO I18n
	"Profile": "Profil", //NO I18n
	"crm.security.group.users.empty": "Kullan\u0131c\u0131 bulunamad\u0131.", //NO I18n
	"crm.label.picklist.none": "Bulunamad\u0131", //NO I18n
	"crm.usrpop.non.selected" : "Se\u00e7ili Kullan\u0131c\u0131lar",//NO I18n
	"crm.zti.label.user": "Kullan\u0131c\u0131 Ad\u0131", //NO I18n
	"crm.label.notSelected" : "Se\u00e7ilmedi",//NO I18n
	"AM" : "\u00d6\u00d6",//NO I18n
	"Call" : "Arama",//NO I18n
	"crm.phoneNo.Link.Title" : "Skype ile arama",//NO I18n
	"crm.button.cancel" : "\u0130ptal",//NO I18n
	"crm.button.save" : "Kaydet",//NO I18n
	"crm.no.data.found" : "Veri bulunamad\u0131.",//NO I18n
	"crm.label.no.options.found" : "Hi\u00e7bir se\u00e7enek bulunamad\u0131.",//No I18n
	"crm.globalsearch.search.title" : "Ara",//No I18n
	"None" : "Se\u00e7iniz",//No I18n
	"crm.label.criteria.pattern" : "Kriter D\u00fczeni",//No I18n
	"crm.label.edit.criteria.pattern" : "Desen D\u00fczenle",//No I18n
	"criteria.error.alert.brackets.count.mismatch" : "D\u00fczen k\u00f6\u015feli ayra\u00e7lar\u0131 e\u015fle\u015fmiyor.",//No I18n
	"criteria.error.alert.brackets.invalid" : "Ko\u015ful i\u015flecinin/i\u015fle\u00e7lerinin yanlar\u0131ndaki k\u00f6\u015feli parantezler ge\u00e7ersiz.",//No I18n
	"crm.criteria.number.notmatch.check" : "L\u00fctfen {0}\u2019deki d\u00fczeni kontrol edin.",//No I18n
	"criteria.error.alert.other.params" : "Bu d\u00fczende ge\u00e7ersiz i\u00e7erik var.", //No I18n
	"crm.label.search.for.users": "Kullan\u0131c\u0131lar\u0131 Ara", //NO I18n
	"criteria.error.alert.andor.rowcount.mismatch" : "\u00d6l\u00e7\u00fct D\u00fczeni, se\u00e7ti\u011finiz ko\u015fullar ile e\u015fle\u015fmiyor.", //No I18n
	"criteria.error.alert.critnum.rowcount.mismatch" : "\u00d6l\u00e7\u00fct D\u00fczeni, se\u00e7ti\u011finiz ko\u015fullar ile e\u015fle\u015fmiyor.", //No I18n
	"crm.wf.usage.date.criteria.error.msg":"Ba\u015flang\u0131\u00e7 Tarihi Biti\u015f Tarihinden sonra olamaz.",//no i18n
	"and" : "ve", //No I18n
	"or" : "veya", //No I18n
	"crm.label.or" : "VEYA", //No I18n
	"crm.label.and" : "VE", //No I18n
	"crm.criteria.fieldlabel.valid.check" : "L\u00fctfen {0} sat\u0131r\u0131na ge\u00e7erli bir alan etiketi girin.", //No I18n
	"crm.criteria.condition.valid.check" : "L\u00fctfen {0} i\u00e7in ge\u00e7erli bir ko\u015ful belirtin.", //No I18n
	"crm.field.valid.check" : "L\u00fctfen ge\u00e7erli bir {0} girin.", //No I18n
	"crm.custom.field.less.than.to" : "<i>Biti\u015f </i> aral\u0131\u011f\u0131 <i>Ba\u015flang\u0131\u00e7</i> aral\u0131\u011f\u0131ndan b\u00fcy\u00fck olamaz.", //No I18n
	"crm.alert.label.savepattern" : "\u00d6l\u00e7\u00fctleri de\u011fi\u015ftirmeden \u00f6nce d\u00fczeni kaydedin.",//No I18n
	"crm.criteria.max.rowcnt.exceeds" : "Daha fazla \u00f6l\u00e7\u00fct ekleyemezsiniz.",//No I18n
	"is" : "e\u015fit",//No I18n
	"isn\'t" : "e\u015fit de\u011fil",//No I18n
	"contains" : "i\u00e7erir",//No I18n
	"doesn\'t contain" : "i\u00e7ermez",//No I18n
	"starts with" : "ile ba\u015flar",//No I18n
	"ends with" : "ile biter",//No I18n
	"is empty" : "bo\u015f",//No I18n
	"is not empty" : "dolu",//No I18n
	"is before" : "\u00f6ncesinde",//No I18n
	"is after" : "sonras\u0131nda",//No I18n
	"between" : "aras\u0131nda",//No I18n
	"not between" : "d\u0131\u015f\u0131nda",//No I18n
	"Today" : "Bug\u00fcn",//No I18n
	"Tommorow" : "Yar\u0131n",//No I18n
	"Tommorow Onwards" : "Yar\u0131ndan itibaren",//No I18n
	"Yesterday" : "D\u00fcn",//No I18n
	"Till Yesterday" : "D\u00fcne kadar",//No I18n
	"Last Month" : "Ge\u00e7en Ay",//No I18n
	"Current Month" : "Bu Ay", //No I18n
	"Next Month" : "\u00d6n\u00fcm\u00fczdeki Ay", //No I18n
	"Last Week" : "Ge\u00e7en Hafta", //No I18n
	"Current Week" : "Bu Hafta", //No I18n
	"Next Week" : "Gelecek Hafta", //No I18n
	"Age in Days" : "G\u00fcn baz\u0131nda", //No I18n
	"Due in Days" : "G\u00fcn Olarak S\u00fcre Dolumu", //No I18n
	"Scheduled" : "Planland\u0131", //No I18n
	"Attended Dialled" : "Kat\u0131ld\u0131 Arand\u0131", //No I18n
	"Unattended Dialled" : "Kat\u0131lmad\u0131 Arand\u0131", //No I18n
	"Overdue" : "Geciken", //No I18n
	"Cancelled" : "\u0130ptal Edildi", //No I18n
	"Received" : "Al\u0131nd\u0131", //No I18n
	"Missed" : "Cevaps\u0131z", //No I18n
	"crm.alert.character.not.allowed" : "{0}- izin verilmiyor", //No I18n
	"crm.condition.in.last" : "son i\u00e7inde", //No I18n
	"crm.zinvoice.dueIn" : "s\u00fcresi:", //No I18n
	"on" : "i\u00e7inde",//No I18n
	"before" : "\u00f6nce",//No I18n
	"crm.label.general.small.after" : "\u015fundan sonra:",//No I18n
	"crm.thisweek" : "Bu Hafta",//No I18n
	"crm.label.this.month" : "Bu Ay",//No I18n
	"crm.thisyear" : "Bu Y\u0131l",//No I18n
	"crm.source.user.and.system" : "Kullan\u0131c\u0131 ve Sistem",//No I18n
	"crm.source.user.or.system" : "Kullan\u0131c\u0131 veya Sistem",//No I18n
	"crm.label.system2" : "Sistem",//No I18n
	"crm.source.user.only" : "Yaln\u0131zca Kullan\u0131c\u0131 taraf\u0131ndan",//No I18n
	"crm.source.system.only" : "Yaln\u0131zca Sistem taraf\u0131ndan",//No I18n
	"crm.condition.till.today" : "Bug\u00fcne Kadar",//No I18n
	"game.month.left" : "1 ay",//No I18n
	"game.months.left" : "{0} ay",//No I18n
	"crm.condition.last.30.days" : "son 30 g\u00fcn i\u00e7inde",//No I18n
	"crm.condition.last.60.days" : "son 60 g\u00fcn i\u00e7inde",//No I18n
	"crm.condition.last.90.days" : "son 90 g\u00fcn i\u00e7inde",//No I18n
	"crm.label.filter.typehere" : "Buraya yaz\u0131n", //No I18N
	"crm.filter.is.not" : "de\u011fil", //No I18n
	"crm.condition.until.now" : "\u015eimdiye Kadar",//No I18n
	"crm.filter.email.isblocked" : "engellendi",//No I18n
	"crm.filter.email.isnotblocked" : "engellenmedi",//No I18n
	"crm.label.no.results.match" : "Sonu\u00e7 bulunamad\u0131",//No I18n
	"crm.label.select.user" : "Kullan\u0131c\u0131lar\u0131 Se\u00e7mek i\u00e7in t\u0131klay\u0131n.", //No I18n
	"current.logged.in.user": "Oturum a\u00e7an Kullan\u0131c\u0131", //NO I18n
	"current.logged.in.user.definition": "Kay\u0131t Eylemini ba\u015flatan kullan\u0131c\u0131.", //NO i18n
	"crm.security.group": "Grup", //NO I18n
	"crm.security.role": "Rol", //NO I18n
	"Date" : "Tarih",//No I18n
	"crm.field.valid.decimal.check2" : "<i>{0}</i> alan\u0131 i\u00e7in ondal\u0131k basama\u011f\u0131 en fazla {1} veya buna e\u015fit olmal\u0131d\u0131r.",//No I18n
	"crm.field.empty.check" : "{0} bo\u015f olamaz.",//No I18n
	"crm.label.add.note": "Not Ekleyin", //NO I18n
	"crm.label.simply.by": "-", //NO I18n
	"crm.general.addnote": "Bir not ekle", //NO I18n
	"crm.general.addtitle": "Ba\u015fl\u0131k ekleyin", //NO I18n
	"crm.label.attach.file": "Dosya ekle", //NO I18N
	"crm.button.mass.delete": "Sil", //NO I18N
	"crm.warning.delete.record": "\"{0}\" \u00f6\u011fesini silmek istedi\u011finizden emin misiniz?", //NO I18N
	"crm.label.yes": "Evet", //NO I18N
	"crm.note.view.previous": "\u00d6nceki Notlar\u0131 G\u00f6r\u00fcnt\u00fcle", //NO I18N
  "of": "\u0131n", //NO I18N
	"crm.label.notes": "Notlar", //NO I18N
	"crm.note.recent.first": "En Son \u00d6nce", //NO I18N
	"crm.note.recent.last": "En Son Sonra", //NO I18N
	"crm.territory.label.only": "Yaln\u0131zca {0}", //no i18n
	"crm.select" : "Se\u00e7in",//No I18n
	"crm.button.apply.filter" : "Filtre Uygula",//No I18n
	"crm.alert.maximum.text.values.contains" : "Bu alan i\u00e7in en fazla {0} de\u011fer girebilirsiniz.",//No I18n
	"PM" : "\u00d6S",//No I18n
	//start-cx create form keys
	"crm.duplicate.value.not.allowed": "Yinelenen de\u011ferlere izin verilmiyor.",//no i18n
	"crm.duplicate.value.available": "Var olan bir {0}, ayn\u0131 {1} sahip, bulundu.",//no i18n
	"crm.duplicate.value.available.multiple": "Birden fazla {0}, ayn\u0131 {1} sahip, bulundu.",//no i18n
	"crm.custombutton.nobuttons.found": "D\u00fc\u011fmeler Bulunamad\u0131",//no i18n
	"crm.custombutton.create.newbutton": "Olu\u015ftur D\u00fc\u011fmesi",//no i18n
	"crm.custombutton.manage.button": "D\u00fc\u011fmeleri Y\u00f6net",//no i18n
	"crm.custombutton.name": "Ad",//no i18n
	"crm.customize.custombutton.function.desc": "A\u00e7\u0131klama",//no i18n
	"crm.custombutton.action": "D\u00fc\u011fme Eylemi",//no i18n
	"crm.custombutton.empty.field.values": "A\u015fa\u011f\u0131daki alan(lar) i\u00e7in bo\u015f de\u011fer bulundu:",//no i18n
	"crm.custombutton.proceed.action": "Yine de devam etmek istiyor musunuz?",//no i18n
	"zb.common.Cancel": "\u0130ptal",//no i18n
	"crm.yes.proceed": "Evet, Devam edin.",//no i18n
	"crm.label.module.merge": "{0}lar\u0131 Birle\u015ftir",//no i18n
	"crm.view.record": "G\u00f6r\u00fcnt\u00fcle {0}",//no i18n
	"crm.tpi.ctiapi.config.choosefile": "Dosya se\u00e7",//no i18n
	"crm.label.subform.addrows": "Sat\u0131r Ekle",//no i18n
	"crm.button.save&new": "Kaydet ve Yeni",//no i18n​
	//end-cx create form keys
	"crm.mb.newversion.msg4" : "Tamam, Anlad\u0131m",//No I18n
	"Jan" : "Oca",//No I18n
	"Feb" : "\u015eub",//No I18n
	"Mar" : "Mar",//No I18n
	"Apr" : "Nis",//No I18n
	"Jun" : "Haz",//No I18n
	"Jul" : "Tem",//No I18n
	"Aug" : "A\u011fu",//No I18n
	"Sep" : "Eyl",//No I18n
	"Oct" : "Ekm",//No I18n
	"Nov" : "Kas",//No I18n
	"Dec" : "Ara",//No I18n
	"crm.label.More" :"Daha fazlas\u0131", //no i18n
	"crm.label.unmapped.stages":"A\u00e7\u0131klanmam\u0131\u015f", //no i18n
	"crm.wf.summary.label.ShowInstantActions" : "Daha Fazla G\u00f6ster",//No I18n
	 "crm.wf.summary.label.HideInstantActions" : "Daha Az G\u00f6ster",//No I18n

	 	 //filter related keys-start
"crm.inv.mail.time.two.days.subject":"{0} - {1}",//no i18n
"crm.lead.prediction.tooltip.convert":"OLASILIK",//no i18n
"crm.lead.prediction.tooltip.score":"PUAN",//no i18n
"Planned":"Planlanan",//no i18n
"Invited":"Davet Edildi",//no i18n
"Sent":"G\u00f6nderildi",//no i18n
"Received":"Al\u0131nd\u0131",//no i18n
"Opened":"A\u00e7\u0131k",//no i18n
"Responded":"Yan\u0131tlanan",//no i18n
"Bounced":"Geri \u00c7evrilen",//no i18n
"Opted\ Out":"Reddedilen",//no i18n
"crm.filter.label.with.open":"A\u00e7\u0131k {0} ile",//no i18n
"crm.filter.label.without.open":"{0} A\u00e7\u0131k Olmadan",//no i18n
"crm.filter.label.with.module":"{0} ile",//no i18n
"crm.filter.label.activity.due":"{0} S\u00fcre",//no i18n
"crm.filter.label.activity.done":"{0} Bitti",//no i18n
"crm.filter.label.without.any":"{0} Olmadan",//no i18n
"Notes":"Notlar",//no i18n
"crm.filter.label.notes.added":"Notlar Eklendi",//no i18n
"crm.label.filter.email.status":"En Son E-posta Durumu",//no i18n
"crm.label.filter.email.clicked":"t\u0131kland\u0131",//no i18n
"crm.label.filter.email.responded":"yan\u0131tlad\u0131" , //no i18n
"crm.label.filter.email.info":"Filtre kay\u0131tlar\u0131 g\u00f6nderdi\u011finiz/ald\u0131\u011f\u0131n\u0131z e-postalar\u0131n en son durumunu esas al\u0131r.",//no i18n
"crm.filter.label.sent":"g\u00f6nderildi",//no i18n
"crm.filter.label.not.sent":"g\u00f6nderilemedi",//no i18n
"crm.filter.label.opened":"a\u00e7\u0131ld\u0131",//no i18n
"crm.filter.label.not.opened":"a\u00e7\u0131lamad\u0131",//no i18n
"crm.filter.label.received":"al\u0131nd\u0131",//no i18n
"crm.filter.label.not.received":"al\u0131nmad\u0131",//no i18n
"crm.filter.label.bounced":"geri d\u00f6nd\u00fc",//no i18n
"crm.filter.label.opened.not.replied":"a\u00e7\u0131ld\u0131 ve yan\u0131tlanmad\u0131", //no i18n
"crm.filter.label.any":"Yukar\u0131dakilerden herhangi biri",//no i18n
"crm.zia.config.potential.amount":"{0} Miktar\u0131",//no i18n
"Quote\ Stage":"{0} A\u015famas\u0131",//no i18n
"crm.module.owner":"{0} Kay\u0131t Sahibi",//no i18n
"Potential\ Closing\ Date":"{0} Kapan\u0131\u015f Tarihi",//no i18n
"crm.lead.prediction.likely.convert":"D\u00f6n\u00fc\u015ft\u00fcrme ihtimali y\u00fcksek",//no i18n
"crm.lead.prediction.convert.high":"Y\u00fcksek",//no i18n
"crm.lead.prediction.convert.medium":"Orta",//no i18n
"crm.lead.prediction.convert.low":"D\u00fc\u015f\u00fck",//no i18n
"crm.predictions.feature.label":"Tahmin",//no i18n
"crm.intelligence.prediction.likelywin":"Kazanmas\u0131 Muhtemel",//no i18n
"crm.intelligence.prediction.likelylose":"Kaybetmesi Muhtemel",//no i18n
"crm.intelligence.prediction.halfchance":"50:50 \u015eans",//no i18n
"crm.intelligence.prediction.score":"Tahmini Skor",//no i18n
"crm.lead.prediction.recent.score":"Son tahmin puan\u0131",//no i18n
"crm.intelligence.prediction.lastconv":"Son 3 g\u00f6r\u00fc\u015fme",//no i18n
"crm.intelligence.prediction.recordsfocus":"Odaklanmak i\u00e7in kay\u0131tlar",//no i18n
"crm.intelligence.prediction.slowmoving":"Yava\u015f Hareketli",//no i18n
"crm.intelligence.prediction.trend.down":"Yak\u0131n Zamanda E\u011filimi d\u00fc\u015fen",//no i18n
"crm.label.touched.records":"Dokunulmu\u015f Kay\u0131tlar",//no i18n
"crm.label.untouched.records":"Dokunulmam\u0131\u015f Kay\u0131tlar",//no i18n
"crm.label.record.action":"Kay\u0131t Eylemi",//no i18n
"workflow.rule.view.label.Modified":"Son De\u011fi\u015fiklik",//no i18n
"crm.label.not.modified":"De\u011fi\u015ftirilmedi",//no i18n
"crm.label.related.records.action":"\u0130lgili Kay\u0131tlar Eylemi",//no i18n
"Done":"Bitti",//no i18n
"crm.label.not.done":"Yap\u0131lmad\u0131",//no i18n
"sentiment.model":"E-posta Duyarl\u0131l\u0131\u011f\u0131",//no i18n
"sentiment.criteria.count":"Say\u0131m",//no i18n
"sentiment.criteria.percentage":"Y\u00fczde",//no i18n
"sentiment.criteria.lastmail":"Son e-posta i\u00e7in",//no i18n
"Chats":"Sohbetler",//no i18n
"Attended":"Kat\u0131lan",//no i18n
"crm.lead.prediction.popup.text":"D\u00f6n\u00fc\u015ft\u00fcrme ihtimali {0} oldu\u011funda, tahmin puan\u0131 aral\u0131\u011f\u0131: {1}.",//no i18n
"crm.lead.prediction.popup.final":"L\u00fctfen filtreyi uygun \u015fekilde de\u011fi\u015ftirin ve yeniden deneyin.",//no i18n
"crm.custom.field.less.than.to1":"Ba\u015flang\u0131\u00e7 aral\u0131\u011f\u0131 Biti\u015f aral\u0131\u011f\u0131ndan b\u00fcy\u00fck olamaz.",//no i18n
"Last\ Activity\ Date":"Son Etkinlik Tarihi",//no i18n
"crm.label.vendor.name":"{0} \u0130sim",//no i18n
"hours":"saat",//no i18n
"days":"g\u00fcn",//no i18n
"weeks":"hafta",//no i18n
"months":"ay",//no i18n
"years":"y\u0131l",//no i18n
"crm.label.general.small.after":"\u015fundan sonra:",//no i18n
"Last\ Week":"Ge\u00e7en Hafta",//no i18n
"Last\ Month":"Ge\u00e7en Ay",//no i18n
"crm.module.name":"{0} Ad\u0131",//no i18n
"Campaign":"Kampanya",//no i18n
"Tasks":"G\u00f6revler",//no i18n
"Calls":"Aramalar",//no i18n
"Events":"Etkinlikler",//no i18n
"sentiment.criteria.wrongcriteria":"\u00d6l\u00e7\u00fct de\u011feri en fazla {0} olabilir",//no i18n
"crm.chosen.minimum.input.text":"{0} veya daha fazla karakter girin",//no i18n
"crm.intelligence.prediction.trendup":"Yukar\u0131 e\u011filim",//no i18n
"crm.intelligence.prediction.trenddown":"A\u015fa\u011f\u0131 e\u011filim",//no i18n
"crm.zia.prediction.notrend":"No trend",//no i18n
"crm.zia.prediction.likelihood":"Likelihood Score",//no i18n
"Completed":"Tamamland\u0131" ,//no i18n
"crm.label.success":"Ba\u015far\u0131l\u0131" ,//no i18n
"crm.label.Failure":"Ba\u015far\u0131s\u0131z" ,//no i18n
"Both":"Her \u0130kisi" ,//no i18n
"crm.condition.cannot.empty":"Ko\u015ful bo\u015f olamaz.",//no i18n
"crm.condition.last.30.days":"son 30 g\u00fcn i\u00e7inde",//no i18n
"crm.condition.last.60.days":"son 60 g\u00fcn i\u00e7inde",//no i18n
"crm.condition.last.90.days":"son 90 g\u00fcn i\u00e7inde",//no i18n
"crm.sentiment.Positive":"Pozitif",//no i18n
"crm.sentiment.Negative":"Negatif",//no i18n
"sentiment.positiveandnegative":"Pozitif ve Negatif",//no i18n
"sentiment.positiveornegative":"Pozitif veya Negatif",//no i18n
"sentiment.positiveonly":"Yaln\u0131zca Pozitif",//no i18n
"sentiment.negativeonly":"Yaln\u0131zca Negatif",//no i18n
"crm.sentiment.Neutral":"N\u00f6tr",//no i18n
"crm.filters.select.campaign.type":"{0} T\u00fcr\u00fcn\u00fc Se\u00e7",//no i18n
"crm.filters.select.campaign.status":"{0} Durumunu Se\u00e7",//no i18n
"campaign.Member" : "\u00dcye",//no i18n
	"Service":"Hizmet",//no i18n
"Activities":"Aktiviteler",//no i18n
"crm.livedesk.pot.nextdays":"Gelecek {0} g\u00fcn",//no i18n
"Today\ +\ Overdue":"Bug\u00fcn + S\u00fcresi ge\u00e7en",//no i18n
"crm.source.user.and.system":"Kullan\u0131c\u0131 ve Sistem",//no i18n
"crm.source.user.or.system":"Kullan\u0131c\u0131 veya Sistem",//no i18n
"User":"Kullan\u0131c\u0131",//no i18n
"crm.source.user.only":"Yaln\u0131zca Kullan\u0131c\u0131 taraf\u0131ndan",//no i18n
"crm.source.system.only":"Yaln\u0131zca Sistem taraf\u0131ndan",//no i18n
"Scheduled":"Planland\u0131",//no i18n
"Attended\ Dialled":"Kat\u0131ld\u0131 Arand\u0131",//no i18n
"Unattended\ Dialled":"Kat\u0131lmad\u0131 Arand\u0131",//no i18n
"Cancelled":"\u0130ptal Edildi",//no i18n
"crm.filter.email.isblocked":"engellendi",//no i18n
"crm.filter.email.isnotblocked":"engellenmedi",//no i18n
"condition.till.now":"\u015eimdiye Kadar",//no i18n
"crm.recurring.no.months":"{0} ay",//no i18n
"crm.lead.prediction.tooltip":"D\u00f6n\u00fc\u015ft\u00fcrme ihtimali y\u00fcksek - Puan Aral\u0131\u011f\u0131",//no i18n
"crm.website.activity":"Websitesi Etkinli\u011fi",//no i18n
"crm.label.By":"Taraf\u0131ndan",//no i18n
"crm.chosen.searching.text":"Ar\u0131yor...",//no i18n
"crm.label.memberstatus.is":"ve \u00dcye Durumu",//no i18n
"crm.events.duration":"S\u00fcre",//no i18n
"crm.title.clear.name":"Temizle",//no i18n
"crm.label.status.is":"ve durum",//no i18n
"zia.last3.help":"G\u00f6r\u00fc\u015fme aramalar, g\u00f6revler, {0}, al\u0131nan e-posta, notlar, ziyaretler, sosyal yorumlar ve Desk\u2019ten gelen destek \u0130steklerini i\u00e7erir.",//no i18n
"crm.label.tag.related.to":"ili\u015fkili",//no i18n

	//filter related keys-End
	"crm.label.account.created" : "Yeni {0} olu\u015fturulacak",//No I18n
	"crm.krp.no.records.found" : "{0} bulunmad\u0131.",//No I18n
	"crm.module.new" : "Yeni {0}",//No I18n
	"crm.label.view" : "G\u00f6r\u00fcn\u00fcm",//No I18n
	"crm.nsocial.customers" : "M\u00fc\u015fteriler",//No I18n
	"crm.nsocial.open.potential" : "A\u00e7\u0131k {0}",//No I18n
	"crm.nsocial.lead.contact" : "{0}/{1}",//No i18n
	"Others" : "Di\u011ferleri",//No i18n
	"crm.field.length.check" : "{0} de\u011feri, maksimum uzunlu\u011fu a\u015f\u0131yor.", //No I18n
	"crm.lower.now": "\u015fimdi",//no i18n
	"crm.time.min.ago": "{0} dak. \u00f6nce",//no i18n
	"crm.time.mins.ago":"{0} dak. \u00f6nce",//no i18n
	"crm.time.hr.ago": "{0} sa. \u00f6nce",//no i18n
	"crm.time.hrs.ago": "{0} sa. \u00f6nce", //no i18n
	"AllUsers": "T\u00fcm Kullan\u0131c\u0131lar", //no i18n
	"crm.label.search":"Ara",//no i18n
	"crm.api.filterby":"Filtre \u00f6l\u00e7\u00fct\u00fc",//no i18n
	"crm.customview.nofields.found":"--Hi\u00e7bir E\u015fle\u015fen Alan Yok--",//no i18n
	"crm.setup.system.ziarecommendation":"\u00d6neri",//no i18n
	"crm.filter.label.all.products":"T\u00fcm {0}",//no i18n
	"crm.filter.label.select.products":"Se\u00e7ili {0}",//no i18n
	"crm.reviewprocess.smart.filter":"G\u00f6zden Ge\u00e7irme S\u00fcreci Kay\u0131t durumu",//no i18n
	"crm.dashboard.sharing.empty.value.alert":"L\u00fctfen bir de\u011fer se\u00e7in.",//no i18n
	"crm.segmentation.segment.score":"Segment Puan\u0131",//no i18n
	"crm.filter.label.in":"dayanak",//no i18n
	"crm.filter.label.and.purchase.in":"ve \u015fu \u015fekilde Sat\u0131n Al\u0131m Yapabilir",//no i18n
	"crm.filter.label.last.purchased":"ve k\u0131sa s\u00fcrede ald\u0131\u011f\u0131",//no i18n
	"crm.filter.label.a.day":"bir G\u00fcn",//no i18n
	"crm.filter.label.a.week":"bir Hafta",//no i18n
	"crm.filter.label.a.month":"bir Ay",//no i18n
	"crm.cal.custom":"\u00d6zel",//no i18n
	"crm.mb.field.common.empt":"De\u011fer bo\u015f olamaz.",//no i18n
	"crm.chosen.error.loading.text":"Hay aksi, sonu\u00e7lar\u0131n\u0131z\u0131 y\u00fckleyemedik",//no i18n
	"crm.filter.label.firstbuy":"\u0130lk defa",//no i18n
	"crm.filter.label.cwbab":"Bakmakla Y\u00fck\u00fcml\u00fc Oldu\u011fu",//no i18n
	"crm.filter.label.fbt":"Grup",//no i18n
	"crm.filter.label.rebuy":"Yinele",//no i18n
	"crm.filter.label.nextbuy":"S\u0131ralama",//no i18n
	"crm.mxnlookup.select" : "{0} Ata",//No I18n
	"crm.lookup.chooserecord":"{0} Se\u00e7",//no i18n
	"crm.record.selected":"Se\u00e7ili {0}",//no i18n
	"crm.module.empty.message" : "{0} bulunamad\u0131",//No I18n
	"crm.mxnlookup.selected" : "Atanan {0}",//No I18n
	"crm.security.error" : "Bu i\u015flemi ger\u00e7ekle\u015ftirmek i\u00e7in yeterli izniniz yok. Y\u00f6neticiniz ile irtibata ge\u00e7in.", //No I18n
	"crm.label.creator.noPermission" : "\u0130zin Reddedildi", //No I18n
	"crm.segmentation.recency" : "Yak\u0131nl\u0131k", //No I18n
	"crm.segmentation.frequency" : "S\u0131kl\u0131k", //No I18n
	"crm.segmentation.monetary" : "Para", //No I18n
	"crm.smartfilter.related.module.msg" : "\u00dc\u00e7ten fazla ilgili mod\u00fcl se\u00e7emezsiniz.", //No I18n
	"crm.smartfilter.related.module.msg1" : "(\u00d6rn: E-posta, Aktiviteler, Notlar)", //No I18n
	"crm.smartfilter.related.module.msg2" : "S\u00fcre bo\u015f b\u0131rak\u0131lamaz", //No I18n
	"crm.label.timeZone": "Zaman Dilimi", //NO I18n
	"crm.label.insufficient.privileges": "Bu i\u015flemi ger\u00e7ekle\u015ftirmek i\u00e7in Ayr\u0131cal\u0131klar Yetersiz. Y\u00f6neticiniz ile ileti\u015fime ge\u00e7in.", //NO I18n
	"crm.filter.header.secton.system": "Sistem Tan\u0131ml\u0131 Filtreler", //NO I18N
	"crm.filter.header.secton.fields": "Alanlara G\u00f6re Filtrele", //NO I18N
	"crm.createfield.calcinfo.new" : "Bu alan, girdi\u011finiz t\u00fcm ifadeler i\u00e7in bir hesap makinesi g\u00f6revi g\u00f6r\u00fcr.</br> <b>\u00d6rn. 20+20</b> otomatik olarak <b>40</b> sonucunu verir",//No i18n
	"crm.lable.read.only" : "Salt okunur alan",//No i18n
	"crm.column.sort.asc" : "Artan",//No I18n
	"crm.column.sort.desc" : "Azalan",//No i18n
	"crm.column.unsort" : "S\u0131ralamay\u0131 kald\u0131r",//No I18n
	"custmr.prtl.notes.shr.to.custmr": "M\u00fc\u015fteriyle payla\u015f", //NO I18N
	"crm.label.edited":"D\u00fczenlendi",//no i18n
	"crm.label.edited.on":"D\u00fczenlenme tarihi",//no i18n
	"crm.message.limit.exceed": "{1} i\u00e7in {0} karaktere izin verilir.", //NO I18N
	"custmr.prtl.notes.shrd.with.custmr": "M\u00fc\u015fteriyle Payla\u015f\u0131ld\u0131", //NO I18N
	"crm.button.ok" : "Tamam", //NO I18N
	"crm.role.already.selected" : "Bu rol zaten se\u00e7ildi", //no i18n
	"crm.user.deleted": "KULLANICI S\u0130L\u0130ND\u0130",  //NO I18N
	"crm.account.closed": "BU HESAP KAPATILDI",  //NO I18N
	"crm.start.chat": "Sohbet ba\u015flat",  //NO I18N
	"crm.start.call": "Bir arama ba\u015flat",  //NO I18N
	"crm.recipient.invalid.email" : "Ge\u00e7ersiz e-postalar bulundu.", //NO I18N
	"crm.recipient.add.recipient" : "\u0130lave Al\u0131c\u0131 Ekle", //NO I18N
	"crm.start.video.call": "Bir g\u00f6r\u00fcnt\u00fcl\u00fc arama ba\u015flat",  //NO I18N //ignorei18n_start

	"crm.label.scoring.rules":"Puanland\u0131rma Kurallar\u0131",
	"Score":"Puan",
	"Positive Score":"Pozitif Puan",
	"Negative Score":"Negatif Puan",
	"Touch Point Score":"Dokunma Puan\u0131",
	"Positive Touch Point Score":"Pozitif Dokunma Puan\u0131",
	"Negative Touch Point Score":"Negatif Dokunma Puan\u0131",
	"crm.label.type.minutes": "Dakika olarak buraya yaz\u0131n", //NO I18N

	"is\ OPEN":"A\u00c7IK",//no i18n
	"is\ WON":"KAZANILDI",//no i18n
	"is\ LOST":"KAYBED\u0130LD\u0130",//no i18n
	"crm.potential.all.open":"T\u00fcm\u00fc A\u00e7\u0131k a\u015famalar\u0131",//no i18n
	"crm.potential.all.won":"T\u00fcm\u00fc Kapal\u0131 Kazan\u0131ld\u0131 a\u015famalar\u0131",//no i18n

	"crm.potential.all.lost":"T\u00fcm\u00fc Kapal\u0131 Kaybedildi a\u015famalar\u0131",//no i18n
	"crm.campaign.member.status" : "\u00dcye Durumu",//no i18n
	"crm.dashboard.select.type" : "{0} Se\u00e7",//no i18n
	"crm.campaign.service.status":"Hizmet Durumu",//no i18n

	"crm.label.addColumn":"S\u00fctun Ekle",//no i18n
	"crm.button.clear.filter":"Filtreyi Kapat",//no i18n
	"crm.button.show.filter":"Filtreyi G\u00f6ster",//no i18n
	"crm.las.error.user.maxlimit":"En fazla 20 kullan\u0131c\u0131 se\u00e7ebilirsiniz.",//no i18n
	"crm.las.error.picklist.maxlimit":"Yaln\u0131zca en fazla 20 se\u00e7enek se\u00e7ebilirsiniz.",//no i18n

	"crm.fileuploader.message.responseerror": "Kar\u015f\u0131ya y\u00fckleme ba\u015far\u0131s\u0131z oldu", //NO I18N
	"crm.storage.create.error":"Veri depolama \u00fcst s\u0131n\u0131r\u0131n\u0131za ula\u015ft\u0131\u011f\u0131n\u0131zdan, yeni kay\u0131t olu\u015fturulam\u0131yor.",//no i18n
	"crm.storage.create.error.client":"Y\u00f6neticiniz depolama \u00fcst s\u0131n\u0131rlar\u0131na ula\u015ft\u0131\u011f\u0131ndan, yeni kay\u0131t olu\u015fturulam\u0131yor. Bu sorunu \u00e7\u00f6zmek i\u00e7in {0}\u2018e ba\u015fvurun.",//no i18n
	"crm.storage.avail.info":"({0} kalan {1})",//no i18n
	"crm.storage.error.key.manage":"Veri depolama alan\u0131n\u0131z\u0131 y\u00f6netin",//no i18n
	"Records":"Kay\u0131tlar",//no i18n
	"crm.workflow.alert.additional.recipients" : "Di\u011fer Al\u0131c\u0131lar", //NO I18N
	"crm.workflow.alert.type.otherEmails" : "Ek e-posta adreslerini ay\u0131rmak i\u00e7in virg\u00fcl kullan\u0131n.", //NO I18N
	"crm.related.contact.account" : "{1} ile ilgili {0}",//No I18n
	"crm.allcontact.show" : "T\u00fcm {0}",//No I18n
	"crm.button.mass.show" : "G\u00f6ster",//No I18n
	"crm.msg.custom.view.not.replied" : "Yan\u0131tlanmayan \u0130letiler", //NO I18N
	"crm.msg.custom.view.replied" : "Yan\u0131tlanan \u0130letiler",//NO I18N
	"crm.workflow.select.recipients" : "Al\u0131c\u0131lar", //NO I18N
	"crm.custom.module.no.profile.selected.alert1":"L\u00fctfen en az bir profil se\u00e7",//NO I18N
	"crm.auto.enrich.remove.default.profile" : "\u00dczg\u00fcn\u00fcz, ancak varsay\u0131lan profili kald\u0131ramazs\u0131n\u0131z.",//NO I18N
	"crm.inv.label.add.emails" : "E-posta Ekle" ,//NO I18N
	"crm.prediction.analytics.filter.year":"Ge\u00e7en Y\u0131l",//no i18n
	"Previous\ FY":"\u00d6nceki Y\u0131l",//no i18n
	"Current\ FY":"\u015eu anki Y\u0131l",//no i18n
	"Next\ FY":"Sonraki Y\u0131l",//no i18n
	"Current\ FQ":"\u015eu anki \u00c7eyrek",//no i18n
	"Next\ FQ":"Sonraki \u00c7eyrek",//no i18n
	"Previous\ FQ":"\u00d6nceki 3ay",//no i18n
	"crm.inv.label.add.emails" : "E-posta Ekle", //NO I18N
	"crm.picklist.sample.text":"\u00d6rnek Metin",//no i18n
	"crm.more.colors":"Daha Fazla Renk",//no i18n
	"crm.button.back.alone":"Geri",//no i18n
	"crm.field.label.email":"E-posta",//no i18n
	"crm.zia.nba.feature.label":"Sonraki en iyi eylem",//no i18n
	"Meeting":"Toplant\u0131",//no i18n
	"Tomorrow":"Yar\u0131n",//no i18n
	"crm.gdpr.notavailable.field":"Kullan\u0131lamaz",//no i18n
	"crm.setup.system.ziasimilarity":"Benzerlik \u00f6nerisi",//no i18n
	"crm.gdpr.notavailable.field":"Kullan\u0131lamaz",//no i18n
	"crm.label.simply.in":"i\u00e7inde",//no i18n
	"crm.filter.label.all.products":"T\u00fcm {0}",//NO I18N
	'crm.zia.nbx.filter.due' : "Due", //NO I18N
	"abm.segment": "Segment", // NO I18N
	"abm.segment.names": "Segment Adlar\u0131", // NO I18N
	"abm.abm.segment.name": "ABM Segment Ad\u0131", // NO I18N
	"abm.segmentation.techniques": "ABM Segmentlere Ay\u0131rma Teknikleri", // NO I18N
	"abm.filters": "ABM Filtreleri", // NO I18N
	"abm.filters.info": "Bu filtreler, {1} ile ili\u015fkili {0} \u00f6\u011fesine dayal\u0131 olarak bilgileri g\u00f6sterir.", // NO I18N
	"abm.rfm.label": "RFM Etiketi", // NO I18N
	"abm.firmographics.label": "Firma \u00d6zellikleri Etiketi" ,// NO I18N
	"abm.recommendation.label": "\u00d6neri Etiketi", // NO I18N
	"abm.voc.label": "VOC Etiketi", // NO I18N
	"abm.engagement.label": "Etkile\u015fim Etiketi", // NO I18N
	"abm.overall.score": "Genel Puan", // NO I18N
	"abm.mood.score": "Ruh Hali Puan\u0131", // NO I18N
	"abm.value.score": "De\u011fer Puan\u0131", // NO I18N
	"abm.engagement.score": "Etkile\u015fim Puan\u0131", // NO I18N
	"abm.rfm.score": "RFM Puan\u0131", // NO I18N
	"crm.customview.pin.column":"Pin Column",//no i18n
"crm.customview.unpin.column":"Unpin Column",//no i18n
"crm.column.list.max.group.limit" : "", //no i18n
	"crux.custom.field.greater.than.equalto":"{0}, {1}’den büyük veya eşit olmalıdır.",
	"crux.users.selected.plural" : "{0} kullanıcı seçildi.",
	"crux.user.selected.singular" :"{0} kullanıcı seçildi.",
	"crux.criteria.empty.secondaryfield.module" : "Hiçbir eşleşen {0} alanı {1} modülünde bulunmadı",
	"crux.criteria.empty.secondaryfield" : "Karşılaştırma için hiçbir başka {0} alanı mevcut değil, lütfen karşılaştırılacak bir değer girin.",
	"crux.logged.in.role.definition" : "Kayıt eylemlerini Başlatan kullanıcı rolü",
	"crux.max.limit.unselect" : "{0} {1}‘den fazla seçimi kaldıramazsınız.", //NO I18N
	"crux.existing.tag" : "\u201c{0}\u201d zaten seçili", //NO i18N
	"crm.label.true" : "Do\u011fru",//NO I18N
	"crm.label.false" : "Yanl\u0131\u015f",//NO I18N
	"crm.record.locked" : "Kilitli",//NO I18N
	"crm.filter.number.range":"{0}-{1} aras\u0131ndaki de\u011ferler",//no i18n
	"crm.smartfilter.picklist.options.msg":"Bu se\u00e7ene\u011fi en fazla 10 se\u00e7ili de\u011fer i\u00e7in se\u00e7ebilirsiniz.",//no i18n
	"crm.chosen.maximum.campaigns.selected":"En fazla 5 adet se\u00e7ebilirsiniz {0}",//no i18n
	"crux.smartfilter.multiselect.maximum.selected":"En fazla {0} {1} se\u00e7ebilirsiniz",//no i18n
	"crm.best.time.column.label":"\u0130leti\u015fim Kurmak \u0130\u00e7in En \u0130yi Zaman",//no i18n
	"crm.competitoralert.mentioned.in.email":"<b>E-posta</b> i\u00e7inde bahsedildi",//no i18n
	"crm.competitoralert.with.sentiment":"Duyarl\u0131l\u0131k \u0130le",//no i18n
	"crm.competitoralert.name.error":"L\u00fctfen rakip ad\u0131 i\u00e7in ge\u00e7erli bir de\u011fer girin.",//no i18n
	"crm.competitoralert.date.error":"L\u00fctfen e-postada rakip bahsetmesi i\u00e7in ge\u00e7erli bir tarih girin.",//no i18n
	"crm.competitoralert.sentiment.error":"L\u00fctfen rakip duyarl\u0131l\u0131\u011f\u0131 i\u00e7in ge\u00e7erli bir se\u00e7enek belirleyin.",//no i18n
	"crm.competitor.name":"Rakip",
"crm.lookup.advance.error.msg" : "Filtre uygulan\u0131rken bir sorun oldu. Sayfay\u0131 yeniden y\u00fckleyin ve yeniden deneyin.",
	"Contact\ Name" : "{0} Ad\u0131",
	"Lead\ Name" : "{0} Ad\u0131",
	"-None-" : "Hi\u00e7biri",
	"January" : "Ocak",
	"February":"\u015eubat",
	"March":"Mart",
	"April":"Nisan",
	"May":"May\u0131s",
	"June":"Haziran",
	"July":"Temmuz",
	"August":"A\u011fustos",
	"September":"Eyl\u00fcl",
	"October":"Ekim",
	"November":"Kas\u0131m",
	"December":"Aral\u0131k",
	"crm.label.add.tags":"Etiketleri Ekle",//no i18n
	"crm.confirm.deassociate":"Atamay\u0131 Kald\u0131r",//no i18n
	// Image Upload Open
	"crm.label.browse.files":"Dosyalara G\u00f6zat",//no i18n
	"crm.label.lowercase.or":"veya",//no i18n
	"crm.image.header":"G\u00f6r\u00fcnt\u00fc",//no i18n
	"crm.image.n.n":"N:N Oran\u0131",//no i18n
	"crm.image.2.2":"2:2 Oran\u0131",//no i18n
	"crm.image.4.3":"4:3 Oran\u0131",//no i18n
	"crm.image.16.9":"16:9 Oran\u0131",//no i18n
	"crm.image.height.width.swap":"Y\u00fcksekli\u011fi ve Geni\u015fli\u011fi De\u011fi\u015ftir",//no i18n
	"crm.image.rotate.image":"D\u00f6nd\u00fcr",//no i18n
	"crm.label.lowercase.of":"/",//no i18n
	"crm.image.empty.name.placeholder" : "Bir ad girin",
	"crm.image.crop.and.rotate" : "K\u0131rp ve D\u00f6nd\u00fcr",
	"crm.image.description" : "A\u00e7\u0131klama Ekleyin...",
	"crm.image.actual.size" : "Ger\u00e7ek Boyut",
	"crm.image.reset" : "S\u0131f\u0131rla",
	"crm.image.width.px" : "px",
	"crm.view.attachment.download":"\u0130ndir",//no i18n
	"crm.label.edit":"D\u00fczenle",//no i18n
	"crm.label.close":"Kapat",//no i18n
	"crm.label.any":"hi\u00e7biri",
	"crm.image.description":"A\u00e7\u0131klama Ekleyin...",//no i18n
	"crm.image.zoom.in":"Yak\u0131nla\u015ft\u0131r",//no i18n
	"crm.image.zoom.out":"Uzakla\u015ft\u0131r",//no i18n
	"crm.label.lowercase.of":"/",//no i18n
	"crm.image.desc.maxsize":"A\u00e7\u0131klama 255 karakteri a\u015fmamal\u0131d\u0131r.",//no i18n
	"crm.image.name.maxsize":"Ad 100 karakteri a\u015fmamal\u0131d\u0131r.",//no i18n
	"crm.fileuploader.message.totalfilesizeexceeded":"Toplam dosya boyutu, izin verilen {0} s\u0131n\u0131r\u0131n\u0131 a\u015f\u0131yor.",//no i18n
	"crm.fileuploader.message.maxfilesexceeded":"En fazla {0} dosyay\u0131 kar\u015f\u0131ya y\u00fckleyebilirsiniz.",//no i18n
	"crm.fileuploader.message.maxfilesexceeded1":"Yaln\u0131zca en fazla {0} dosya ekleyebilirsiniz.",//no i18n
	"crm.ImageuploadField.size.limit":"Toplam g\u00f6r\u00fcnt\u00fc boyutu, izin verilen {0} MB s\u0131n\u0131r\u0131n\u0131 a\u015f\u0131yor.",//no i18n
	"crm.attachment.imageUploadField.Minlen.check":"Yaln\u0131zca 1 g\u00f6r\u00fcnt\u00fc se\u00e7ebilirsiniz",//no i18n
	"crm.image.crop.error":"G\u00f6r\u00fcnt\u00fc k\u0131rp\u0131lam\u0131yor. L\u00fctfen tekrar deneyin!",//no i18n
	"crm.attach.here":"burada.",//no i18n
	"crm.image.unsupported.corrupted.single":"Kar\u015f\u0131ya y\u00fcklemeye \u00e7al\u0131\u015ft\u0131\u011f\u0131n\u0131z foto\u011fraf bozuk.",//no i18n
	"Browse":"G\u00f6zat",//no i18n
	"crm.imageupload.failure":"Baz\u0131 resimler kar\u015f\u0131ya y\u00fcklenemedi. L\u00fctfen daha sonra yeniden deneyin.",//no i18n
	"zc.editor.width":"Geni\u015flik",//no i18n
	"ze.editor.height":"Y\u00fckseklik",//no i18n
	"crm.label.delete":"Sil",//no i18n
	"crm.image.error.gif":"GIF Resimler k\u0131rp\u0131lamaz.",//no i18n
	"crm.fileuploader.message.morefiles":"{0} di\u011fer dosyalar",//no i18n
	"crm.fileuploader.message.invalidfileType1":"{0} dosyas\u0131 desteklenmiyor.",//no i18n
	"crm.fileuploader.message.invalidfileType2":"{0} ve {1} dosyalar\u0131 desteklenmiyor.",//no i18n
	"crm.attach.upload.sizelimit":"Toplam boyut s\u0131n\u0131r\u0131",//no i18n
	
	
	
	"crm.fileuploader.message.maxfilesexceeded" : "En fazla {0} dosyay\u0131 kar\u015f\u0131ya y\u00fckleyebilirsiniz.",
"crm.file.upload.maxlength.exceeds" : "{0} i\u00e7ine yaln\u0131zca bir dosyay\u0131 kar\u015f\u0131ya y\u00fckleyebilirsiniz."


}
